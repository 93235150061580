/* Ensure that Quill editor container and toolbar get the correct styles */
.ql-container.ql-snow {
    margin-bottom: 8px !important; /* Apply margin */
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1) !important; /* Box shadow */
    border-radius: 8px !important; /* Border radius */
    border: 1px solid #303030 !important; /* Solid border */
  }
  
  /* Apply styles to the Quill toolbar */
  .ql-toolbar.ql-snow {
    position: sticky; /* Keeps the toolbar visible when scrolling */
    top: 0; /* Position at the top of its container */
    z-index: 1000; /* Ensure it's above other content */
    border-radius: 8px 8px !important; /* Rounded top corners */
    border: 1px solid #303030 !important; /* Solid border */
    background-color: #121212 !important; /* Transparent background */
    margin-bottom: 10px;
  }
  

  .ql-picker.ql-color .ql-picker-item {
    border: 2px solid #ccc; /* Adjust the border style and color as needed */
    margin: 2px; /* Optional: Add space between color rectangles */
    border-radius: 4px; /* Optional: Add rounded corners */
  }
  
  /* Optional: Add hover effect for the color rectangles */
  .ql-picker.ql-color .ql-picker-item:hover {
    border-color: #666; /* Change the border color on hover */
  }

  .ql-editor h5 {
    font-size: 14px !important; /* Adjust the font size */
  }

  .ql-editor .ql-bullet {
    list-style-type: disc; /* Ensure bullets for unordered lists */
  }
  
  .ql-editor .ql-ordered {
    list-style-type: decimal; /* Ensure numbers for ordered lists */
  }
  
  .ql-editor ul{
    padding-inline-start: 40px;
  }
  .ql-editor ul li{
    list-style-type: unset;
    padding-left: 0;
  }

  .ql-editor ol {
    list-style-type: decimal;
  }
  .ql-editor li[data-list="bullet"] {
    list-style-type: disc; 
  }
  